<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Comment{{ search}}</h1>
      <div>
        <button type="button" 
          v-b-tooltip.hover.v-primary
          @click="exportToCSV"
          title="Export CSV"
          variant="outline-primary"
          class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button>
        <!-- <b-modal id="showmodal" ref="showmodal" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12">
                <h2>Comment </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <b-col md="12">
              <div class="d-flex align-items-center">
                <div>
                <div class="mb-half">Name</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.name ? showItem.name : '(not set)'  }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="12" class="d-flex align-items-center mt-2">
              <div>
                <div class="mb-half">Email</div>
                  <p class="font-weight-bolder mt-1">{{ showItem.email }}</p>
              </div>
            </b-col>
            <b-col v-if="showItem.country_code"  md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Country Code</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.country_code }}</div>
              </div>
            </b-col>
            <b-col v-if="showItem.user_id ? showItem.user_id.user_name : false" md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">User Name</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.user_id.user_name  }}</div>
              </div>
            </b-col>
            <b-col  md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Phone</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.phone }}</div>
              </div>
            </b-col>
            <b-col  md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Subject</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.subject }}</div>
              </div>
            </b-col>
            <b-col    md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Message</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.message }}</div>
              </div>
            </b-col>
            <b-col    md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Created At</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.created_at }}</div>
              </div>
            </b-col>
            </b-row>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" @click="$refs.showmodal.hide()">
              Close
            </button>
            </div>
          </b-modal> -->

        <!-- <b-popover
          ref="popover"
          target="popover-button-variant"
          triggers="click"
          class="custom-popover"
          :show.sync="popoverShow"
        >
          <button
            @click="onPopovcerOpen"
            class="notofication-btn"
            style="color: #408dff; margin: 5px"
            size="sm"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn notofication-btn"
            style="background: #f4f9fd; margin: 8px"
          >
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button>
          <button
            type="button"
            class="btn notofication-btn ml-1"
            style="background: #f4f9fd; margin: 8px"
          >
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button
            type="button"
            @click="HandelClickMultipleDelete()"
            class="btn notofication-btn"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px"
          >
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </b-popover> -->

      </div>
    </div>

    <div v-if="isLoading" class="loading" >
     <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
     </div>
    <div v-else class="drop-zone">
      <draggable
        v-model="commentData"
        handle=".draggable-task-handle"
        tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline"
      >
        <b-card
          no-body
          class="card-revenue-budget dash-total-card mb-2 drag-el"
          v-for="(item,index) in commentData"
          @dblclick="handelShow(item)"
          :key="index"
        >
          <b-row class="p-1">
            <b-col md="3" class="d-flex">
                <div class="ml-1  mr-1">
                  <div class="font-weight-bolder text-black mb-half">
                    Name
                  </div>
                  {{ item.username ? item.username: '(not set)'  }}
              </div>
            </b-col>
            <b-col md="3" class="d-flex">
              <div>
                  <div class="font-weight-bolder text-black mb-half">
                    Comment
                  </div>
                  <div style="max-width: 15cm;">{{ item.comment}}</div>
                </div>
            </b-col>
            <b-col  md="2" class="d-flex">
              <div>
                <div class="mb-half">Coupon Code</div>
                <div class="font-weight-bolder text-black">
                    {{ item.coupon_code}}
                </div>
              </div>
            </b-col>
            <b-col  md="2" class="d-flex">
              <div>
                <div class="mb-half">Created At</div>
                <div class="font-weight-bolder text-black">
                    {{ item.created_at}}
                </div>
              </div>
            </b-col>
            <b-col  md="2" class=" d-flex align-items-center">
                <b-form-checkbox v-model="item.status" :checked="item.status" class="my-checkbox ml-1" name="check-button" @change="HandelClickCheckBox(item.id)" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
              </b-col>
          </b-row>
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !commentData.length }"></div>
    </div>

    <button v-if="!isLoading" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <!-- <button
      v-if="popoverShow  && !isLoading"
      id="seeall-btn"
      type="button"
      class="btn see-btn"
       @click="selectAllClick()"
    >
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{selectAllLabel}}</p>
      </div>
    </button> -->
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { BImg } from 'bootstrap-vue'
import json2csv from 'json2csv';

import vSelect from 'vue-select';
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  components: {
    draggable,
    vSelect,
    quillEditor,BImg
  },
  data() {
    return {
      mainProps: {
      width: 70,
      height: 70,
      class: 'm1',
    },
      data: {},
      filter:'All',
      selectAllLabel:'Select All',
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      AdPlacemnetOption:[],
      commentData: [],
      commentData1: [],
      popoverShow: false,
      edit:false,
      popoverShow1: false,
      pageNo:1,
      paginationStart:0,
      paginationEnd:10,
      paginationLength:0,
      clientNameEng:'',
      isLoading:false,
      clientNameEArb: '',
      category: [],
      category_ids: [],
      descriptionEng: '',
      descriptionArb: '',
      status: '',
      sequence: '',
      checkBoxIds:{},
      websiteUrl: '',
      imageUrl: '',
      selectAll:false,
      keywordEng: '',
      popoverShow3: {},
      keywordArb: '',
      showItem:{}
    };
  },
  created() {
  },
  mounted(){
    setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Comment')
        if(is_valid.length < 1){this.$router.push('/')}
    this.getClientStore();
    }, 500);
  },
    computed:{
      search() {
         // let data = []
         let search = this.$store.state.searchData
          // data = this.commentData1.filter(item => (item.user_name?.replace(/\s/g,'').toLowerCase().includes(search) 
          //                                              || item.id.toString().includes(search) 
          //                                              || item.email.replace(/\s/g,'').toLowerCase().includes(search) 
          //                                              || item.created.replace(/\s/g,'').toLowerCase().includes(search)
          //                                              || (item.is_active == 1 ? 'Active' : 'Inactive').replace(/\s/g,'').toLowerCase().includes(search)
          //                                              ))
          if(this.$store.state.application.id){
          this.getSearch(search);
          }
          setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Comment')
        if(is_valid.length < 1){this.$router.push('/')}
    }, 500);
        },
    },
  methods: {
    handelShow(item){
      this.$refs.showmodal.show()
      this.popoverShow3={}
      this.showItem = item
    },
    HandelClickCheckBox(id){
      this.$http.post(`${this.baseURL}/comment/enable-disable`,{id:id},
              {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials:true
            }).then((res)=>{  
          console.log(res,'res')
          this.$http.post( `${this.baseURL}/comment/index`,{pageno:this.pageNo}).then((res)=>{
            this.commentData = res.data.data
            this.paginationLength = res.data.totaldata  
            this.commentData1 = res.data.data
            this.statusUpdate()
          })
            })
    },
     getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/comment/search`,{search:search,pageno:this.pageNo},
              {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials:true
            }).then((res)=>{  
          console.log(res,'res')
            this.commentData = res.data.data
            this.paginationLength = res.data.totaldata  
            this.commentData1 = res.data.data
            this.statusUpdate()
            this.isLoading = false
            })
          // }
    }, 1000),
    // getAdPlacemnetToName(id){
    //   let data = this.AdPlacemnetOption.filter(item => item.id == id)
    //   return (data.length > 0 ? data[0].name : '')
    // },
    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      // let array = []
      // this.commentData.map(item => {
      //   let data = {
      //   name_en:item.name_en,
      //   name_ar:item.name_ar,
      //   category: item.categories.map((item)=>{ return item.name}).join(", "),
      //   description_en:item.description_en,
      //   description_ar:item.description_ar,
      //   is_active:item.is_active,
      //   sequence:item.sequence,
      //   website:item.website,
      //   image:item.image,
      //   search_keyword_en:item.search_keyword_en,
      //   search_keyword_ar:item.search_keyword_ar,
      // }
      // array.push(data);
      // })
      const csvData = json2csv.parse(this.commentData)
      ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'Comment.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeModal(){
        this.$refs['modal'].hide()
    },
    handelChangeFilter(){
      console.log(this.filter)
      if(this.filter == "All"){
        this.commentData = this.commentData1
      }
      else if(this.filter == "Active"){
        let data = []
        data = this.commentData1.filter(item => item.is_active == 1)
        this.commentData = data
      }
      else if(this.filter == "Inactive"){
        let data = []
        data = this.commentData1.filter(item => item.is_active == 0)
        this.commentData = data
      }
    },
    onPopovcerOpen3(id) {
      this.popoverShow3[id] = this.popoverShow3[id] == undefined ? false : this.popoverShow3[id];
      this.popoverShow3[id] = this.popoverShow3[id] == true ? false : true;
      this.$forceUpdate()
    }, 
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },
    pagechange(){
      if(this.pageNo>(this.paginationLength/10)){
        this.pageNo = parseInt(this.paginationLength/10)
      }
      if(this.pageNo == 0){
        this.pageNo = 1
      }
        this.paginationStart = (this.pageNo*10)-10
        this.paginationEnd = (this.pageNo*10)
      let search = this.$store.state.searchData
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
    },
    statusUpdate(){
      this.commentData.map(item => {if(item.status){item.status = true}else{item.status = false}})
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
        this.$http.post( `${this.baseURL}/comment/search`,{search:search,pageno:this.pageNo}).then((res)=>{
            console.log(res.data.data,"res-=-=")
            this.commentData=res.data.data
            // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
            this.paginationLength = res.data.totaldata
            this.commentData1=res.data.data
            this.statusUpdate()
            this.isLoading = false
          })
      }, 1000),
      normalPaginationHandelClick: _.debounce(function () {
        this.isLoading = true
        this.$http.post( `${this.baseURL}/comment/index`,{pageno:this.pageNo}).then((res)=>{
            console.log(res.data.data,"res-=-=")
            this.commentData=res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
            // this.pageNo=res.data.page
            this.paginationLength = res.data.totaldata
            this.commentData1=res.data.data
            this.statusUpdate()
            this.isLoading = false
          })
      }, 1000),
    paginationHandelClick(value){
      let search = this.$store.state.searchData
      if(value == 'back'){
          if( this.paginationStart > 1){
            this.pageNo--
            this.paginationStart = this.paginationStart - 10
            this.paginationEnd = this.paginationEnd - 10
           if(search){
            this.searchPaginationHandelClick(search,value)
           }else{
            this.normalPaginationHandelClick(search,value)
           }
          }
      }
      else if(value == 'next'){
          if( this.paginationEnd < this.paginationLength){
            this.pageNo++
            this.paginationStart = this.paginationStart + 10
            this.paginationEnd = this.paginationEnd + 10
           if(search){
            this.searchPaginationHandelClick(search,value)
           }else{
            this.normalPaginationHandelClick(search,value)
           }
          }
      }
    },
    HandelClickMultipleDelete(){
          let arr = []
         Object.keys(this.checkBoxIds).map((key) => {
            if( this.checkBoxIds[key] == true){
            arr.push(key)
            }
          });
        this.$http.post(`${this.baseURL}/comment/multi-delete`,{ids:arr},
              {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials:true
            }).then((res)=>{
              this.$swal({
                title: "Deleted",
                text: res.data.message
                  ? `${res.data.message}`
                  : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getClientStore()
              this.popoverShow= false
              this.checkBoxIds = {}
              this.$forceUpdate()
            })
    },
    handelDelete(id){
      this.$http.post(`${this.baseURL}/comment/delete`,{id:id},
              {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials:true
            }).then((res)=>{
              this.$swal({
                title: "Deleted",
                text: res.data.message
                  ? `${res.data.message}`
                  : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getClientStore()
      this.onPopovcerOpen3(id)
      this.checkBoxIds = {}
      this.popoverShow3[id] = false
      this.getClientStore()
      this.$forceUpdate()
            })
    },
    selectAllClick(){
       let data  = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
       console.log(data)
          if(data.length == 10){
            this.selectAllLabel = 'Select All'
            this.checkBoxIds = {}
         }else{
            this.selectAllLabel = 'Deselect All'
          // console.log('enter',this.paginationStart,this.paginationEnd,this.commentData,this.checkBoxIds)
          for(let x = this.paginationStart; x < this.paginationEnd; x++){
            this.checkBoxIds[this.commentData[x].id] = true
          }
         }
         this.$forceUpdate()
    },
    CheckBoxHandelClick(id){
      // this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
      // this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
      let data  = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
          if(data.length == 10){
            this.selectAllLabel = 'Deselect All'
          }else{
            this.selectAllLabel = 'Select All'
          }
          this.$forceUpdate()
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    async getClientStore(){
      this.isLoading = true
      this.$http.post( `${this.baseURL}/comment/index`,{pageno:this.pageNo}).then((res)=>{
            console.log(res.data.data,"res-=-=")
            this.commentData = res.data.data
            this.paginationLength = res.data.totaldata  
            this.commentData1 = res.data.data
            this.statusUpdate()
            this.isLoading = false
          })
      // this.$http.post(`${this.baseURL}/ad-placement/index`).then((res)=>{
      //       console.log(res.data.data,"res-=-=")
      //       this.AdPlacemnetOption = res.data.data
      //       this.checkBoxIds = {}
      //     })
    
      // let data = await getcommentData();
      // console.log(data,"data=====");
    }
  },
};
</script>

<style scope lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.success-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.danger-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.custom-switch .custom-control-label .switch-icon-right{
  right: -5px !important;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  
}
.modal-body {
    padding: 0px 40px 25px 40px !important;
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}
.popover1-body {
  padding: 0;
}
.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>
